import React from "react";
import logo from "./logo.svg";
import "./assets/style.css";
import "./assets/fonts/converted/ms_sans_serif.woff";
import "./assets/fonts/converted/ms_sans_serif.woff2";
import "./assets/fonts/converted/ms_sans_serif_bold.woff";
import "./assets/fonts/converted/ms_sans_serif_bold.woff2";
import "./assets/fontawesome/css/all.min.css";
import "./App.css";

import Home from "./components/Home";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

const App: React.FC = () => {    
    return (
        <>
            <Router>
                <Routes>
                    <Route path="/" element={<Home />} />
                </Routes>
            </Router>
        </>
    );
};

export default App;
