import React, { useState, useEffect, useRef } from "react";
import Layout from "./Layout";
import { TokenData } from "../interfaces/TokenData";
import { DesktopIcon } from "../interfaces/DesktopIcon";
import { getTokenData } from '../services/tokenDataService';
import ieIcon from '../assets/img/icons/msie1-2.png';
import documentsIcon from '../assets/img/icons/directory_closed_cool-0.png';
import binIcon from '../assets/img/icons/recycle_bin_full-4.png';

interface WindowProps {
    children: React.ReactNode;
    icon: string;
    title: string;
    initialX: number;
    initialY: number;
    width: number;
    height: number;
    flatten: boolean;
    hideOutside: boolean;
}

const WinWindow: React.FC<WindowProps> = ({ children, icon, title, initialX, initialY, width, height, flatten, hideOutside }) => {
    const [isDragging, setIsDragging] = useState(false);
    const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const windowRef = useRef<HTMLDivElement>(null);
    const [zIndex, setZIndex] = useState(1);
    const [display, setDisplay] = useState(false);
    
    const handleMouseDown = (e: React.MouseEvent) => {
        if (e.target instanceof HTMLElement && e.target.closest('.title-bar')) {
            setZIndex(1000);
            setIsDragging(true);
            const rect = windowRef.current?.getBoundingClientRect();
            if (rect) {
                setDragOffset({
                    x: e.clientX - rect.left,
                    y: e.clientY - rect.top
                });
            }
        }
    };

    const handleMouseMove = (e: React.MouseEvent) => {
        if (isDragging) {
            setPosition({
                x: e.clientX - dragOffset.x,
                y: e.clientY - dragOffset.y
            });
        }
    };

    const handleMouseOut = () => {
        setIsDragging(false);
        setZIndex(1);
    }

    const handleMouseUp = () => {
        setIsDragging(false);
        setZIndex(1);
    };

    const handleClose = () => {
        setDisplay(false);
    };
    
    useEffect(() => {     
        setPosition({
            x: initialX,
            y: initialY
        })

        setDisplay(true);
        return () => {
        };
    }, []);


    if(!display) return <></>;

    return (
        <div onMouseMove={handleMouseMove} onMouseOut={handleMouseOut} onMouseDown={handleMouseDown} onMouseUp={handleMouseUp} className="window" ref={windowRef} 
             style={{ 
                 width: width + "px",
                 height: height + "px",
                 position: "absolute",
                 top: position.y != 0 ? position.y + "px" : "calc(50% - " + height/2 + "px  )",
                 left: position.x != 0 ? position.x + "px" : "calc(50% - " + width/2 + "px  )",
                 zIndex: zIndex, 
                 display: display ? "block" : "none"
             }}>
            <div className="title-bar" style={{ display: hideOutside ? 'none' : 'flex' }}>
                <div className="title-bar-text" style={{ display: "flex", alignItems: "center" }}>
                    <img width="16px" style={{ marginRight: "4px" }} src={icon} alt={title} />{title}
                </div>
                <div className="title-bar-controls">                                
                    <button onClick={() => { handleClose(); }} aria-label="Close"></button>
                </div>
            </div>
            <div className="window-body" style={{ margin: flatten ? '0px' : '' }}>
                {children}
            </div>
        </div>
    );
};

export default WinWindow;
