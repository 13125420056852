import React, { useState, useEffect, useRef } from "react";
import Layout from "../components/Layout";
import { TokenData } from "../interfaces/TokenData";
import { DesktopIcon } from "../interfaces/DesktopIcon";
import { getTokenData } from '../services/tokenDataService';
import ieIcon from '../assets/img/icons/msie1-2.png';
import documentsIcon from '../assets/img/icons/directory_closed_cool-0.png';
import binIcon from '../assets/img/icons/recycle_bin_full-4.png';
import errorIcon from '../assets/img/icons/restrict-1.png';
import warningIcon from '../assets/img/icons/msg_warning-0.png';
import pumpIcon from '../assets/img/icons/pump.png';
import noIcon from '../assets/img/icons/no-1.png';
import msnIcon from '../assets/img/icons/msn3-5.png';
import rataImg from '../assets/img/viruses/Esmagares_a_minha_rata.png';
import china1Img from '../assets/img/viruses/china1.png';
import virus1 from '../assets/img/viruses/virus (1).webp';
import virus2 from '../assets/img/viruses/virus (2).png';
import virus3 from '../assets/img/viruses/virus (2).webp';
import virus4 from '../assets/img/viruses/virus (3).webp';
import virus5 from '../assets/img/viruses/virus (3).png';
import virus6 from '../assets/img/viruses/virus (4).png';
import distressed1 from '../assets/img/distressed_meme.jpg';
import Window from "./WinWindow";

const Home: React.FC = () => {
    const [tokenData, setTokenData] = useState<TokenData>();
    
    const [desktopIcons, setDesktopIcons] = useState<DesktopIcon[]>([]);
    const [selectedIcon, setSelectedIcon] = useState<DesktopIcon | null>(null);
    const [windows, setWindows] = useState<JSX.Element[]>([]);
    const [spawnWindowPosition, setSpawnWindowPosition] = useState({ x: 0, y: 0 });    
    const [shouldSpam, setShouldSpam] = useState(false);

    let spam: NodeJS.Timer | null = null;
    const generatePopup = () => {
        if(!shouldSpam) return;

        if (!spam) {
            spam = setInterval(() => {
                showRandomWindow();
            }, 1000);
        }

        if (windows.length >= 50) clearInterval(spam)
    }

    useEffect(() => {
        setDesktopIcons([
            {
                "name": "Internet Explorer",
                "icon": ieIcon
            },
            {
                "name": "My Documents",
                "icon": documentsIcon
            },
            {
                "name": "Recycle Bin",
                "icon": binIcon
            },
            {
                "name": "MSN",
                "icon": msnIcon
            },
            {
                "name": "pump.fun",
                "icon": pumpIcon
            }
        ])

        let playSoundInterval: NodeJS.Timeout;

        const loadData = () => {
            setTokenData(getTokenData());
            console.log('call to load data');    
        }

        setShouldSpam(true);
        generatePopup();

        loadData();
        return () => {
        };
    }, [windows]);

    const handleIconClick = (icon: DesktopIcon) => {
        setSelectedIcon(icon);
    }

    const handleIconDoubleClick = (icon: DesktopIcon) => {
        if(icon.name === 'pump.fun' && tokenData != null) {
            window.open(tokenData.photonUrl, '_blank');
        }

        setShouldSpam(true);
        playErrorSound();
        //console.log('double click');
        setWindows(prevWindows => [...prevWindows, createDefaultErrorWindow(icon)]);
    }

    const [players, setPlayers] = useState<any>([]);

    const addPlayer = (source : string) => {
       setPlayers([...players, { id: Date.now(), src: source }]);
    };

    const playErrorSound = () => {        
        addPlayer("./sounds/error.mp3");
    }
    
    const createDefaultErrorWindow = (icon: DesktopIcon): JSX.Element => {        
        setSpawnWindowPosition({ x: spawnWindowPosition.x + 10, y: spawnWindowPosition.y + 10 })
        console.log(spawnWindowPosition);

        const defaultErrorWindowW = 430;
        const defaultErrorWindowH = 130;

        let positionLeft = 0;
        let positionTop = 0;

        if(windows.length > 0) {
            const minPositionTop = 300;
            const maxPositionTop = window.innerHeight - minPositionTop; // Adjust based on the height of the viewport
            const minPositionLeft = 300;
            const maxPositionLeft = window.innerWidth - minPositionLeft; // Adjust based on the width of the viewport            
            positionLeft = Math.random() * (maxPositionLeft - minPositionLeft) + minPositionLeft;
            positionTop = Math.random() * (maxPositionTop - minPositionTop) + minPositionTop;
        }

        return (
            <Window 
                initialX={positionLeft}
                initialY={positionTop}
                icon={''} 
                title={''} 
                // icon={icon.icon} 
                // title={icon.name} 
                width={defaultErrorWindowW}
                height={defaultErrorWindowH}
                flatten={false}
                hideOutside={false}
            >
            <div style={{ margin: "31px 10px 37px 10px" }}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", float: "left" }}>
                    <img src={errorIcon} alt="Error" />                
                </div>
                <div style={{ marginLeft: "40px" }}>
                    <p>Windows has detected a security breach on your computer network. Spyware virus may have infected your operating system. Please visit {tokenData?.photonUrl} for technical support.</p>
                </div>
            </div>
        </Window>);
    }

    const getRandomX = (width: number) => {
        const minPositionLeft = 300;
        const maxPositionLeft = window.innerWidth - minPositionLeft - width; // Adjust based on the width of the viewport            
        return Math.random() * (maxPositionLeft - minPositionLeft) + minPositionLeft;
    }    

    const getRandomY = (height: number) => {
        const minPositionTop = 300;
        const maxPositionTop = window.innerHeight - minPositionTop - height; // Adjust based on the height of the viewport
        return Math.random() * (maxPositionTop - minPositionTop) + minPositionTop;
    }

    const createHueChange = () => {
        let desktop = (document.getElementsByClassName('desktop')[0] as HTMLElement);
        desktop.style.filter = 'hue-rotate(45deg)';
    }

    const createInverseEffect = () => {
        let desktop = (document.getElementsByClassName('desktop')[0] as HTMLElement);
        desktop.style.filter = 'invert(1)';
    }

    const createRotateEffect = () => {
        let desktop = (document.getElementsByClassName('desktop')[0] as HTMLElement);
        desktop.style.transform = 'rotate(180deg)';
    }

    const applyRandomEffect = () => {        
        let array = [
            createHueChange,
            createInverseEffect,
            createRotateEffect,
            applyDistressedBg1
        ]

        let randomIndex = Math.floor(Math.random() * array.length);
        array[randomIndex]();
    }

    const applyDistressedBg1 = () => {
        let desktop = (document.getElementsByClassName('desktop')[0] as HTMLElement);
        desktop.style.backgroundImage = `url(${distressed1})`;
    }

    const showRandomWindow = () => {
        if(windows.length > 50) return;

        let array = [
            createChina1Window,
            createRataWindow,
            createVirus1Window,
            createVirus2Window,
            createVirus3Window,
            createVirus4Window,
            createVirus5Window,
            createVirus6Window
        ]

        playErrorSound();
        let randomIndex = Math.floor(Math.random() * array.length);
        setWindows(prevWindows => [...prevWindows, array[randomIndex]()]);

        console.log(windows.length);
        if(windows.length >= 10 && windows.length % 4 === 0) {
            applyRandomEffect();
        }
    }

    const createChina1Window = (): JSX.Element => {        
        const defaultErrorWindowW = 894;
        const defaultErrorWindowH = 590;
        
        let positionLeft = getRandomX(defaultErrorWindowW);
        let positionTop = getRandomY(defaultErrorWindowH);

        return (
            <Window 
                initialX={positionLeft}
                initialY={positionTop}
                icon={warningIcon} 
                title={'在 SOLANA 上购买病毒 在 SOLANA 上购买病毒 在 SOLANA 上购买病毒'} 
                width={defaultErrorWindowW}
                height={defaultErrorWindowH}
                flatten={true}
                hideOutside={false}
            >
            <div style={{ margin: "0px" }}>
                <img src={china1Img} alt="Error" />                
            </div>
        </Window>);
    }

    const createRataWindow = (): JSX.Element => {        
        const defaultErrorWindowW = 313;
        const defaultErrorWindowH = 244;

        let positionLeft = 0;
        let positionTop = 0;

        return (
            <Window 
                initialX={positionLeft}
                initialY={positionTop}
                icon={warningIcon} 
                title={'Conheces uma gaja em 30 minutos'} 
                width={defaultErrorWindowW}
                height={defaultErrorWindowH}
                flatten={false}
                hideOutside={false}
            >
            <div style={{ margin: "0px" }}>
                <img src={rataImg} alt="Error" />                
            </div>
        </Window>);
    }
    
    const createVirus1Window = (): JSX.Element => {        
        const defaultErrorWindowW = 392;
        const defaultErrorWindowH = 276;

        let positionLeft = getRandomX(defaultErrorWindowW);
        let positionTop = getRandomY(defaultErrorWindowH);

        return (
            <Window 
                initialX={positionLeft}
                initialY={positionTop}
                icon={errorIcon} 
                title={'VIRUSES DETECTED IN YOUR SYSTEM'} 
                width={defaultErrorWindowW}
                height={defaultErrorWindowH}
                flatten={true}
                hideOutside={false}
            >
            <div style={{ margin: "0px" }}>
                <img src={virus1} alt="Error" />                
            </div>
        </Window>);
    }
    
    const createVirus2Window = (): JSX.Element => {        
        const defaultErrorWindowW = 600;
        const defaultErrorWindowH = 294;

        let positionLeft = getRandomX(defaultErrorWindowW);
        let positionTop = getRandomY(defaultErrorWindowH);

        return (
            <Window 
                initialX={positionLeft}
                initialY={positionTop}
                icon={warningIcon} 
                title={'Security Alert - You have been a bad boy'} 
                width={defaultErrorWindowW}
                height={defaultErrorWindowH}
                flatten={true}
                hideOutside={false}
            >
            <div style={{ margin: "0px" }}>
                <img src={virus2} alt="Error" />                
            </div>
        </Window>);
    }

    const createVirus3Window = (): JSX.Element => {        
        const defaultErrorWindowW = 719;
        const defaultErrorWindowH = 403;

        let positionLeft = getRandomX(defaultErrorWindowW);
        let positionTop = getRandomY(defaultErrorWindowH);

        return (
            <Window 
                initialX={positionLeft}
                initialY={positionTop}
                icon={warningIcon} 
                title={''} 
                width={defaultErrorWindowW}
                height={defaultErrorWindowH}
                flatten={true}
                hideOutside={true}
            >
            <div style={{ margin: "0px" }}>
                <img src={virus3} alt="Error" />                
            </div>
        </Window>);
    }

    const createVirus4Window = (): JSX.Element => {        
        const defaultErrorWindowW = 294;
        const defaultErrorWindowH = 409;
        
        let positionLeft = getRandomX(defaultErrorWindowW);
        let positionTop = getRandomY(defaultErrorWindowH);

        return (
            <Window 
                initialX={positionLeft}
                initialY={positionTop}
                icon={warningIcon} 
                title={'GOOGLE WARNING - VIRUS DETECTED'} 
                width={defaultErrorWindowW}
                height={defaultErrorWindowH}
                flatten={true}
                hideOutside={false}
            >
            <div style={{ margin: "0px" }}>
                <img src={virus4} alt="Error" />                
            </div>
        </Window>);
    }

    const createVirus5Window = (): JSX.Element => {        
        const defaultErrorWindowW = 700;
        const defaultErrorWindowH = 614;

        let positionLeft = getRandomX(defaultErrorWindowW);
        let positionTop = getRandomY(defaultErrorWindowH);

        return (
            <Window 
                initialX={positionLeft}
                initialY={positionTop}
                icon={warningIcon} 
                title={'Conheces uma gaja em 30 minutos'} 
                width={defaultErrorWindowW}
                height={defaultErrorWindowH}
                flatten={true}
                hideOutside={true}
            >
            <div style={{ margin: "0px" }}>
                <img src={virus5} alt="Error" />                
            </div>
        </Window>);
    }
    
    const createVirus6Window = (): JSX.Element => {        
        const defaultErrorWindowW = 446;
        const defaultErrorWindowH = 469;

        let positionLeft = getRandomX(defaultErrorWindowW);
        let positionTop = getRandomY(defaultErrorWindowH);

        return (
            <Window 
                initialX={positionLeft}
                initialY={positionTop}
                icon={errorIcon} 
                title={'John McAfee is hunting you down'} 
                width={defaultErrorWindowW}
                height={defaultErrorWindowH}
                flatten={true}
                hideOutside={false}
            >
            <div style={{ margin: "0px" }}>
                <img src={virus6} alt="Error" />                
            </div>
        </Window>);
    }

    if (!tokenData) return <div>loading</div>

    return (
        <Layout>
            <div data-name="inner-content" className="desktopIconContainer1">
            {desktopIcons.map(e => (<>
                <div 
                    onDoubleClick={() => handleIconDoubleClick(e)}
                    data-name={e.name} 
                    onClick={() => handleIconClick(e)} 
                    className={selectedIcon === e ? 'selected-icon' : ''}
                    style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", margin: "10px" }}>
                    <img src={e.icon} alt={e.name} />
                    <span style={{ color: "white", fontSize: "12px", marginTop: "4px" }}>{e.name}</span>  
                </div>
                </>))}
            </div>
            {windows}
            
            {players.map((player: { id: React.Key | null | undefined; src: string | undefined; }) => (
                <div key={player.id}>                
                    <audio autoPlay>
                        <source src={player.src} />
                    </audio>
                </div>
            ))}
        </Layout >
    );
};

export default Home;

